<template>
  <el-container direction="vertical">
    <Header></Header>
    <el-main>
      <!--广告-->
      <div class="ad">
        <!--广告容器-->
        <div class="ad_container">
          <!--分栏广告-->
          <BannerAds></BannerAds>
          <!--文字广告-->
          <TextAds class="mt22"></TextAds>
        </div>
      </div>
      <!--面包屑-->
      <div class="bread_crumbs">
        <div class="bread_content">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item>首页</el-breadcrumb-item>
            <el-breadcrumb-item>视频</el-breadcrumb-item>
            <el-breadcrumb-item>{{ article_data.title }}</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
      </div>
      <!--内容-->
      <div class="content">
        <div class="left">
          <!--标题-->
          <div class="title">{{ article_data.title }}</div>
          <!--内容-->
          <div class="describe" v-html="article_data.content"></div>
          <!--下载按钮-->
          <div class="download" v-if="!hasInfoProperty">
            <el-button size="large" type="primary" @click="is_buy">购买隐藏信息 (售价: {{ article_data.download.integral }}积分)</el-button>
          </div>
          <!--下载信息-->
          <div v-else>
            <div class="title" style="color: #409EFF; border-left: 2px solid #409EFF;">帖子隐藏信息</div>
            <div class="describe">{{ article_data.download.info }}</div>
          </div>
          <!--广告-->
          <BannerAds class="mb15"></BannerAds>
        </div>
        <div class="right">
          <!--热门内容-->
          <div class="items">
            <div class="title">热门内容</div>
            <div class="popular_item">
              <p class="ftb">热门内容</p>
              <p>123123123122222222222222222222222222222222222222222222222</p>
            </div>
            <div class="popular_item">
              <p class="ftb">热门内容</p>
              <p>123123123122222222222222222222222222222222222222222222222</p>
            </div>
            <div class="popular_item">
              <p class="ftb">热门内容</p>
              <p>123123123122222222222222222222222222222222222222222222222</p>
            </div>
          </div>
          <div class="items">
            <div class="title">热门内容</div>
            <div class="popular_image_item">
              <img src="https://picsum.photos/200" alt="热门内容图片">
              <p class="ftb">热门内容标题</p>
            </div>
            <div class="popular_image_item">
              <img src="https://picsum.photos/200" alt="热门内容图片">
              <p class="ftb">热门内容标题</p>
            </div>
          </div>
          <!--二维码-->
          <div class="items qr_code">
            <div class="qr_code_item">
              <img src="https://picsum.photos/200" alt="QQ群二维码">
              <p>
                QQ扫描二维码
                <br>
                加入QQ群
              </p>
            </div>
            <div class="qr_code_item">
              <img src="https://picsum.photos/200" alt="微信群二维码">
              <p>
                微信扫描二维码
                <br>
                加入微信群
              </p>
            </div>
          </div>
        </div>
      </div>
    </el-main>
    <Footer></Footer>
  </el-container>
</template>

<script>
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import BannerAds from "../../components/BannerAds";
import {get_article_details, post_article_buy} from "../../api/api";
import {ElMessage, ElMessageBox} from 'element-plus';


export default {
  name: "DataDetail",
  beforeCreate() {
    // 设置CSS样式
    document.querySelector("body").setAttribute("style", "margin: 0;padding: 0;")
  },
  created() {
    if(this.$route.query.Id !== null && this.$route.query.Id !== ""){
      this.article_id = this.$route.query.Id;
      this.obtain_article_info(this.$route.query.Id);
    }
  },
  data(){
    return {
      article_id: 0,
      article_data: {
        download: {
          integral: 0
        }
      }
    }
  },
  methods: {
    obtain_article_info(article_id){
      get_article_details({
        Id: article_id
      }).then(response => {
        if(response.Code === 1){
          this.article_data = response.Data;
        }
      });
      console.log(this.article_data);
    },
    is_buy(){
      ElMessageBox.confirm(
          "是否购买该附件?",
          "提示",
          {
            confirmButtonText: "购买",
            cancelButtonText: "不购买",
            type: "warning"
          }
      ).then(() => {
        this.buy_article();
      }).catch(() => {
        ElMessage({
          type: "info",
          message: "取消购买"
        });
      });
    },
    buy_article(){
      post_article_buy({article_id: this.article_id}).then(response => {
        if(response.Code === 1){
          ElMessage({
            type: "success",
            message: "购买成功"
          });
          location.reload();
        }else {
          ElMessage({
            type: "error",
            message: response.Message
          });
        }
      });
    }
  },
  computed: {
    hasInfoProperty() {
      // eslint-disable-next-line no-prototype-builtins
      return this.article_data.download.hasOwnProperty('info');
    }
  },
  components: {
    Header,
    Footer,
    BannerAds
  }
}
</script>

<style scoped>
@import "../../static/universal/index.css";

.bread_crumbs{
  height: 36px;
  background: #2e2f31;
  color: #FFFFFF;
}

.bread_crumbs > .bread_content{
  width: 1050px;
  height: 36px;
  line-height: 36px;
  margin: 0 auto;
  font-size: 18px;
}

.bread_crumbs > .bread_content > .el-breadcrumb{
  line-height: 36px;
  font-size: 16px;
  color: #FFFFFF;
}

.bread_crumbs > .bread_content > .el-breadcrumb{
  line-height: 36px;
  font-size: 16px;
}

.bread_crumbs > .bread_content > .el-breadcrumb >>> .el-breadcrumb__inner{
  color: #FFFFFF;
}

.content {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding-top: 10px;
}

.content > .left {
  flex: 1;
  padding: 25px;
  background: #FFFFFF;
  margin-bottom: 10px;
  color: #666666;
}

.title{
  font-weight: bold;
  border-left: 2px solid #34b261;
  color: #34b261;
  padding-left: 9px;
  margin-bottom: 15px;
  font-size: 14px;
}

.content > .left > .describe{
  font-size: 12px;
  margin-bottom: 15px;
}

.content > .left > .el-button{
  width: 100%;
  color: #FFFFFF;
}

.content > .left > .download{
  margin: 20px 0;
}

.content > .left > .download > .el-button{
  width: 100%;
}

.content > .right {
  width: 348px;
  margin-left: 10px;
}

.content > .right > .items{
  padding: 25px;
  margin-bottom: 10px;
  background: #FFFFFF;
  color: #666666;
}

.content > .right > .items > .popular_item{
  margin-bottom: 15px;
}

.content > .right > .items > .popular_item > .title{
  font-weight: bold;
}

.content > .right > .items > .popular_item > p{
  font-size: 12px;
  line-height: 17px;
  word-break: break-all;
  word-wrap: break-word;
}

.content > .right > .items > .popular_image_item{
  display: flex;
  align-items: flex-start;
  margin-bottom: 15px;
}

.content > .right > .items > .popular_image_item > img{
  width: 130px;
  height: 68px;
  margin-right: 10px;
}

.content > .right > .items > .popular_image_item > p{
  font-size: 12px;
  line-height: 17px;
}

</style>